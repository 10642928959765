import { useResponsiveContext } from "context/ResponsiveContext";
import Grid from "elements/Grid";
import HouseIcon from "public/vp-house.svg";
import MoneyIcon from "public/vp-money.svg";
import PersonIcon from "public/vp-person.svg";
import HouseIcon2 from "public/vp-house-2.svg";
import MoneyIcon2 from "public/vp-money-2.svg";
import PersonIcon2 from "public/vp-person-2.svg";
import ScreenIcon from "public/landing/Commercial/Commercial-Screen-Icon.svg";
import GrowthIcon from "public/landing/Commercial/Commercial-Growth-Icon.svg";
import GiftIcon from "public/landing/Commercial/Commercial-Gift-Icon.svg";
import StarIcon from "public/landing/star.svg";
import StarPartial from "public/landing/star-partial.svg";
import Link from "next/link";
import constants, {
	APPEAL_GENERIC_AVERAGE_SAVINGS_FORMATTED,
} from "utils/constants";

export const cardItems = [
	{
		icon: HouseIcon,
		text: "86% of customers save",
	},
	{
		icon: PersonIcon,
		text: "5/5 customers would recommend",
	},
	{
		icon: MoneyIcon,
		text: `Customers save an average of ${APPEAL_GENERIC_AVERAGE_SAVINGS_FORMATTED}`,
	},
];

export const cardItemsBTest = [
	{
		icon: MoneyIcon,
		text: `${APPEAL_GENERIC_AVERAGE_SAVINGS_FORMATTED} average customer savings`,
	},
	{
		icon: PersonIcon,
		text: (
			<>
				<span className="landing-vp3-card-rating">
					4.8&nbsp; <StarIcon />
					<StarIcon />
					<StarIcon />
					<StarIcon />
					<StarPartial />
				</span>
				over {constants.GOOGLE_REVIEW_COUNT} reviews
			</>
		),
		link: "/#landing-reviews-section",
	},
	{
		icon: HouseIcon,
		text: "86% of customers receive a reduction",
	},
];

export const targetCardItems = [
	{
		icon: HouseIcon,
		text: "86% of customers save",
	},
	{
		icon: PersonIcon,
		text: "5/5 customers would recommend",
	},
	{
		icon: MoneyIcon,
		text: "Customers save $1000's",
	},
];

export const partnerCardItems = [
	{
		icon: HouseIcon2,
		text: "86% of customers save",
	},
	{
		icon: PersonIcon2,
		text: "5/5 customers would recommend",
	},
	{
		icon: MoneyIcon2,
		text: `Customers save an average of ${APPEAL_GENERIC_AVERAGE_SAVINGS_FORMATTED}`,
	},
];

export const commercialCardItems = [
	{
		icon: ScreenIcon,
		text: "Spend more time on what matters.",
	},
	{
		icon: GrowthIcon,
		text: "Improve your bottom line.",
	},
	{
		icon: GiftIcon,
		text: "No flat or upfront fees, ever.",
	},
];

export type ValuePropCard = {
	icon: any;
	text: string | JSX.Element;
	link?: string;
};

const ValuePropCards = ({
	cards,
	gridClassName = "",
}: {
	cards: ValuePropCard[];
	gridClassName?: string;
}) => {
	const { isTablet } = useResponsiveContext();

	return (
		<div id="value-prop-cards">
			<Grid>
				{isTablet ? (
					<div className={`landing-vp3-m-card ${gridClassName ?? ""}`}>
						{cards.map(({ icon: Icon, text }, i) => (
							<div className="landing-vp3-m-card-section" key={"vp3-m-" + i}>
								<Icon
									className="landing-vp3-m-card-icon"
									height="56px"
									width="56px"
								/>
								<h4 className="landing-vp3-m-card-title">{text}</h4>
							</div>
						))}
					</div>
				) : (
					cards.map(({ icon: Icon, text, link }, i) => {
						if (link) {
							return (
								<Link
									href={link}
									key={"vp3-" + i}
									style={{ gridColumn: "span 4", width: "100%" }}>
									<div className={`landing-vp3-card ${gridClassName ?? ""}`}>
										<Icon
											height="100%"
											width="100%"
											className="landing-vp3-card-icon"
										/>
										<p className="lg landing-vp3-card-title">{text}</p>
									</div>
								</Link>
							);
						} else {
							return (
								<div
									className={`landing-vp3-card ${gridClassName ?? ""}`}
									key={"vp3-" + i}>
									<Icon
										height="100%"
										width="100%"
										className="landing-vp3-card-icon"
									/>
									<p className="lg landing-vp3-card-title">{text}</p>
								</div>
							);
						}
					})
				)}
			</Grid>
		</div>
	);
};

export default ValuePropCards;
